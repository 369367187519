<template>
  <v-footer
    dark
    class="ctk-footer"
  >
    <template>
      <v-container grid-list-md text-center pt-5>
        <v-layout wrap>
          <v-flex xs12 sm12 md4 lg4 pl-10 class="hidden-sm-and-down hidden-md-and-down">
            <h5 class="pb-3 text-left ctk-footer-title">{{this.$t("footer.contacts")}}</h5>
            <ul class="text-left">
              <li>
                <span>{{this.$t("footer.telephone")}}：</span><span>{{info.tel}}</span>
              </li>
              <li>
                <span>{{this.$t("footer.fax")}}：</span><span>{{info.fax}}</span>
              </li>
              <li>
                <span>{{this.$t("footer.address")}}：</span><span>{{info.addr}}</span>
              </li>
            </ul>
          </v-flex>
          <v-flex xs12 sm12 md4 lg4 pl-10 class="hidden-sm-and-down hidden-md-and-down">
            <section>
              <h5 class="pb-3 ctk-footer-name text-left">{{this.$t("name")}}</h5>
              <div class="text-left">
                <v-img
                  width="150px"
                  :src="info.qrcode"
                ></v-img>
              </div>
            </section>
          </v-flex>
          <v-flex xs12 sm12 md4 lg4 pl-10 class="hidden-sm-and-down hidden-md-and-down">
            <h5 class="pb-3 ctk-footer-title text-left">{{this.$t("footer.links")}}</h5>
            <ul class="ctk-footer-link">
              <template v-for="(link,i) in info.link">
                <li  :key="i" v-if="link.type === 0">
                  <a v-bind:href="link.url">{{link.title}}</a>
                </li>
              </template>
            </ul>
          </v-flex>
          <v-flex xs12 class="ctk-footer-copyright">
            &copy;2019 Citiking All Rights Reserved | <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">备案号: {{info.icp}}</a>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  }
}
</script>
